<template lang="pug">
  svg#cross_shadoffsearch.close_svg(width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg')
    rect(:fill='changeFill' x='6.18066' y='8.82962' width='3.7461' height='21.2279' rx='1.87305' transform='rotate(-45 6.18066 8.82962)')
    rect(:fill='changeFill' x='21.1909' y='6.18069' width='3.7461' height='21.2279' rx='1.87305' transform='rotate(45 21.1909 6.18069)')
</template>

<script>
export default {
  name: 'CrossShadowOffSearch',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    isHoveredCross: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 30,
    },
    width: {
      type: Number,
      default: 28,
    },
    color: {
      type: String,
      default: '#84bace',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else if (this.isHoveredCross) {
        return '#0c93dd';
      } else {
        return this.color;
      }
    }
  },
};
</script>